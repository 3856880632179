import { inject, Injectable } from '@angular/core';
import { LegalFormDto, LegalFormsService } from '@icp/interfaces';
import { TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CachedLegalFormsService {
    private legalForms$ = new BehaviorSubject<LegalFormDto[] | null>(null);
    private transloco = inject(TranslocoService);
    private legalFormsService = inject(LegalFormsService);

    listLegalForms(): Observable<LegalFormDto[]> {
        if (this.legalForms$.value) {
            return this.legalForms$.asObservable() as Observable<LegalFormDto[]>;
        }
        this.legalForms$.next([]);
        return this.legalFormsService
            .listLegalForms(this.transloco.getActiveLang())
            .pipe(tap((result) => this.legalForms$.next(result)));
    }
}
