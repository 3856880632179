import { inject, Injectable } from '@angular/core';
import { BaseCurrenciesService, EconomiesService, LegalFormDto, RolesService } from '@icp/interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, skip, switchMap } from 'rxjs';

import * as AuthActions from '../../auth/store/auth.actions';
import { CachedPlaceTypesService, ErrorService } from '../../core/services';
import { CachedLegalFormsService } from '../../core/services/cached-legal-forms.service';
import { SharedActions } from './shared.actions';

export const ECONOMY_STORAGE = 'Icp_Economy';

@Injectable({ providedIn: 'root' })
export class SharedEffects {
    private actions$ = inject(Actions);
    private economiesService = inject(EconomiesService);
    private rolesService = inject(RolesService);
    private errorService = inject(ErrorService);
    private placeTypesService = inject(CachedPlaceTypesService);
    private legalFormsService = inject(CachedLegalFormsService);
    private basecurrenciesService = inject(BaseCurrenciesService);

    onLogoutClearEconomy = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.logoutSuccess),
            map(() => SharedActions.getDashboardEconomySuccess({ economy: null })),
        );
    });

    fetchAvailableRoles = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedActions.fetchAvailableRoles),
            switchMap((action) =>
                this.rolesService.getAvailableRoles().pipe(
                    map((roles) => SharedActions.fetchAvailableRolesSuccess({ availableRoles: roles })),
                    catchError((error) =>
                        this.errorService.handleError(action, SharedActions.fetchAvailableRolesFailed, error),
                    ),
                ),
            ),
        ),
    );

    onGetEconomy = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedActions.getDashboardEconomy),
            switchMap((action) => {
                if (action.economyId) {
                    return this.economiesService.getEconomyById(action.economyId).pipe(
                        map((economy) => SharedActions.getDashboardEconomySuccess({ economy })),
                        catchError((error) =>
                            this.errorService.handleError(action, SharedActions.getDashboardEconomyFailed, error),
                        ),
                    );
                }
                return of(SharedActions.getDashboardEconomySuccess({ economy: null }));
            }),
        ),
    );

    fetchUserRolesOnEconomyChange = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedActions.getDashboardEconomySuccess),
            skip(1),
            map((action) => AuthActions.getUserRoles({ redirect: false })),
        ),
    );

    storeEconomyInLocalStorage = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SharedActions.getDashboardEconomySuccess),
                map((action) => {
                    if (action.economy) {
                        localStorage.setItem(ECONOMY_STORAGE, JSON.stringify(action.economy));
                    } else {
                        localStorage.removeItem(ECONOMY_STORAGE);
                    }
                }),
            ),
        { dispatch: false },
    );
    fetchBaseCurrencies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedActions.fetchBaseCurrencies),
            switchMap((action) =>
                this.basecurrenciesService.listBaseCurrencies().pipe(
                    map((currencies) => SharedActions.fetchBaseCurrenciesSuccess({ baseCurrencies: currencies })),
                    catchError((error) =>
                        this.errorService.handleError(action, SharedActions.fetchBaseCurrenciesFailed, error),
                    ),
                ),
            ),
        ),
    );

    getAvailablePlaceTypes = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedActions.getAvailablePlaceTypes),
            switchMap(() => {
                return this.placeTypesService.placeTypes$.pipe(
                    map((placeTypes) => SharedActions.getAvailablePlaceTypesSuccess({ placeTypes })),
                    catchError((error) => of(SharedActions.getAvailablePlaceTypesFailed({ error }))),
                );
            }),
        ),
    );

    getLegalForms = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedActions.getLegalForms),
            switchMap((action) =>
                this.legalFormsService.listLegalForms().pipe(
                    map((legalForms: LegalFormDto[]) => SharedActions.getLegalFormsSuccess({ legalForms })),
                    catchError((error) =>
                        this.errorService.handleError(action, SharedActions.getLegalFormsFailed, error),
                    ),
                ),
            ),
        ),
    );
}
